<template>
  <div class="enterpriseInfo">
    <div class="search">
      <el-button @click="addClick" class="butColor" type="primary" size="small">新建</el-button>
      <el-form ref="searchForm" :inline="true" :model="searchForm" label-width="100px">
        <el-form-item label="关键字">
          <el-input v-model="searchForm.ptmc" placeholder="请输入平台名称"></el-input>
        </el-form-item>
        <el-form-item label=" ">
          <el-button @click="getUserList">查询</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="table_box">
      <el-table class="table" :data="tableData" style="width: 100%">
        <el-table-column label="序号" type="index" show-overflow-tooltip width="50"></el-table-column>
        <el-table-column prop="ptmc" label="平台名称" align="center"></el-table-column>

        <el-table-column prop="remarks" label="备注" align="center"></el-table-column>

        <el-table-column prop="creator" label="创建人" align="center"></el-table-column>
        <el-table-column fixed="right" label="操作" width="200" align="center">
          <template slot-scope="scope">
            <div slot="avatar" class="operation">
              <span @click="eait(scope.row)">编辑</span>
              <span @click="delect(scope.row)">删除</span>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="table_pagination">
        <pagination :total="total" :currentPage="searchForm.page" :pageSize="searchForm.size" @pagination="pageChange"/>
      </div>
      <!-- 添加 -->
      <el-dialog destroy-on-close :title="titles" :visible.sync="dialogVisible" width="30%">
        <el-form ref="addForm" :rules="rules" :model="addForm" label-width="130px">
          <el-form-item label="平台名称" prop="ptmc">
            <el-input :disabled="infoState" v-model="addForm.ptmc" placeholder="请输入任务名称"></el-input>
          </el-form-item>
          <el-form-item label="备注" prop="remarks">
            <el-input :disabled="infoState" type="textarea" placeholder="请输入备注" v-model="addForm.remarks"></el-input>
          </el-form-item>
        </el-form>
        <span v-if="!infoState" slot="footer" class="dialog-footer">
          <el-button size="small" @click="dialogVisible = false">取 消</el-button>
          <el-button size="small" class="butColor" type="primary" @click="saveFrom(addForm, 'addForm')">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import pagination from '@/components/pagination.vue';
import {getPage, postSave, ptglDelete} from '@/request/api/ptgl.js';

export default {
  components: {pagination},

  data() {
    return {
      tableData: [],
      addForm: {},
      total: 0,
      searchForm: {},
      dialogVisible: false,
      titles: '新建',

      loading: true,
      // 菜单禁用
      infoState: false,
      rules: {
        ptmc: [{required: true, message: '请输入平台名称', trigger: 'blur'}],
        remarks: [{required: true, message: '请输入备注', trigger: 'blur'}]
      }
    };
  },
  watch: {},
  mounted() {
    this.getUserList();
  },
  methods: {
    // 新建
    addClick() {
      this.infoState = false;
      this.titles = '新建';
      this.$nextTick(() => {
        this.dialogVisible = true;

        this.addForm = {};
      });
    },

    // 获取用户列表
    getUserList() {
      getPage('/pro11/ptgl/page', {
        page: 1,
        size: 10,
        ...this.searchForm
      }).then((res) => {
        if (res.data.state == 'success') {
          this.tableData = res.data.data;
          this.total = res.data.size;
        }
      });
    },

    pageChange({page, limit}) {
      this.searchForm.page = page;
      this.searchForm.size = limit;
      this.getUserList();
    },
    // 保存
    saveFrom(data, dom) {
      let url = '/pro11/ptgl/save';
      this.$refs[dom].validate((valid) => {
        if (valid) {
          postSave(url, data).then((res) => {
            console.log(res, '接口数据');
            if (res.data.state == 'success') {
              this.dialogVisible = false;
              this.getUserList();
              this.$message.success(res.data.msg);
            }
          });
        }
      });
    },

    /**
     * @description: loadingg 关闭
     * @param {*}
     */

    exitLoading() {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    },
    /**
     * @description:  修改
     * @param {*} data
     */
    eait(data) {
      this.titles = '修改';
      this.dialogVisible = true;
      this.addForm = data;
    },

    /**
     * @description: 删除
     * @param {*} data
     */
    delect(data) {
      this.$confirm(`此操作将永久删除 ${data.ptmc} 信息, 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
          .then(() => {
            ptglDelete(`/pro11/ptgl/delete/${data.id}`).then((res) => {
              if (res.data.state == 'success') {
                this.loading = true;
                this.$message.success(res.data.msg);
                this.getUserList();
              } else {
                this.$message.error(res.data.msg);
              }
            });
          })
          .catch(() => {
            this.$message({
              type: 'info',
              message: '已取消删除'
            });
          });
    }
  }
};
</script>
<style scoped lang="less">
@import '~@/style/colorPublic';

.butColor {
  // background: @but_color;
  background: #409eff;
}

.enterpriseInfo {
  background: #fff;
  padding: 2vh;
  box-sizing: border-box;
}

.operation {
  display: flex;
  justify-content: center;

  span {
    cursor: pointer;
    margin-right: 1vh;
  }
}

.table {
  overflow-y: auto;
}

.pageStyle {
  text-align: @pageAlign;
  margin-top: 1vh;
}

.search {
  display: flex;
  align-items: center;
  margin-bottom: 1vh;
}

.searchForm {
  display: flex;
  position: relative;
}

.searchForm /deep/ .el-form-item {
  margin-bottom: 0;
}

.searchForm /deep/ .el-input__inner {
  // height: 4vh !important;
  height: 30px !important;
  line-height: 4vh !important;
}

.searchForm /deep/ .el-button--default {
  // height: 4vh !important;
  height: 30px !important;
  padding: 9px 19px;
}

.accept {
  cursor: pointer;
  background: #3366cc;
  color: #fff;
  border-radius: 5px;
  padding: 0.5vh;
  box-sizing: border-box;
}

// .search{
//     border: 1px solid #ccc;
//     padding: 1vh;
//     box-sizing: border-box;
// }
.search {
  display: flex;
  align-items: center;
  margin-bottom: 1vh;
  // border: 1px solid #ccc;
  padding: 1vh;
  box-sizing: border-box;
}

.searchForm {
  display: flex;
  position: relative;
}

.searchForm /deep/ .el-form-item {
  margin-bottom: 0;
}

.receip /deep/ .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 10vw;
  height: 10vw;
  line-height: 10vw;
}

.receip /deep/ .avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.receip /deep/ .avatar-uploader-icon {
  color: #8c939d;
  width: 10vw;
  height: 10vw;
  line-height: 10vw;
  text-align: center;
}

.receip /deep/ .avatar {
  width: 10vw;
  height: 10vw;
  display: block;
}

@media screen and (max-width: 1500px) {
  .searchForm /deep/ .el-input__inner {
    height: 5vh !important;
    width: 380px;
    line-height: 5vh !important;
  }

  .searchForm /deep/ .el-button--default {
    height: 5vh !important;
    padding: 9px 19px;
  }

  .searchBut {
    position: absolute;
    right: 0;
    top: 0;
  }

}
</style>
